import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入样式
// import './assets/style/pacman-canvas.css'
import './assets/style/default.scss'

// 引入element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element);
import { encrypt } from "@/js/encrypt";

Vue.prototype.encrypt = encrypt

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
