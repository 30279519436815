<template>
  <div class="game">
    <div class="game-title flex align-items-center justify-content-between">
      <div
        class="flex align-items-center pl10"
        style="width: calc((100% - 300px) / 2)"
      >
        <img src="../../assets/img/Icon-32x32.png" height="24" width="24" />
        <span class="pl20">File</span>
        <span class="pl20">Edit</span>
        <span class="pl20">Add</span>
        <span class="pl20">View</span>
        <span class="pl20">Tools</span>
        <span class="pl20">Help</span>
      </div>
      <div>Creator</div>
      <div
        class="pr10 flex align-items-center justify-content-end pointer"
        style="width: calc((100% - 300px) / 2)"
        @click="goHomePage"
      >
        x
      </div>
    </div>
    <div class="game-top flex align-items-center justify-content-end pr20">
      <button @click="goGame()">Start Game</button>
    </div>
    <div class="game-body flex align-items-center">
      <div class="game-edit pl10 pr10 pt20">
        <div class="game-edit-title font-bold font-size-xl">
          <!-- 请选择"{{ dict[selected] }}"的样式: -->
          {{showText(selected)}}
        </div>
        <div
          class="game-edit-list flex flex-column align-items-center"
          v-if="pacmanItem[selected]"
        >
          <div
            class="game-edit-item mb20"
            :class="{
              'game-edit-item-active': item.value == gameConfig[selected],
            }"
            v-for="item in pacmanItem[selected]"
            :key="item.value"
            @click="changePreConfig(selected,item)"
          >
            <img :src="item.img" width="220px" />
            <div class="flex align-items-center justify-content-center">
               {{ item.title }}<span class="icon"></span>125
            </div>
          </div>
        </div>
      </div>
      <div class="game-content flex align-items-center justify-content-center">
        <div class="wapper">
          <div class="box">
            <div id="poker_table" class="poker-table" :style="{backgroundImage: preConfig.bgImage}" @click="handleEdit('bg')">
              <div class="table-foot">
            <div class="fold button" @click.stop="handleEdit('foldButton')" :style="{backgroundImage:preConfig.foldButtonBg}">Fold</div>
            <div class="raise">
              <img
                src="../../assets/pokerImages/skin1/warn.png"
                alt=""

              />Current raise:30<br />You need 20 more to call.
            </div>
            <div class="call button" @click.stop="handleEdit('foldButton')" :style="{backgroundImage:preConfig.foldButtonBg}">Call $ 20</div>
          </div>
              <div class="select">
            <div class="name selector">Name</div>
            <div class="speed selector">
              Speed
              <select name="" id="" style="color: white; cursor: pointer">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div class="daker selector" @click="changeTextColor">Darker</div>
            <div class="help selector">Help</div>
            <div class="check selector">Check for</div>
          </div>
              <!-- <div class="myPhoto"></div> -->
              <div class="foot">
            <ul>
              <!-- <li class="bottomButton">20</li>
              <li class="bottomButton">40</li>
              <li class="bottomButton">60</li>
              <li class="bottomButton">80</li>
              <li class="bottomButton">100</li>
              <li class="bottomButton">120</li>
              <li class="bottomButton">All in!</li> -->
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >20</li>
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >40</li>
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >60</li>
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >80</li>
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >100</li>
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >120</li>
              <li class="bottomButton" :style="{backgroundImage:preConfig.foldButtonBg}" >All in!</li>
            </ul>
          </div>
              <div class="board" ref="table" @click.stop="handleEdit('table')" :style="{backgroundImage:preConfig.table}" @mouseenter="changeTable('tableEnter')" @mouseleave="changeTable('tableLeave')">
                <div class="board_core">
                  <div class="boardcard_core">
                    <div id="flop1" class="boardcard" @click.stop="handleEdit('poker')" :style="{backgroundImage: preConfig.poker}" @mouseenter="changeTable('enter')" @mouseleave="changeTable('leave')"></div>
                    <div id="flop2" class="boardcard" @click.stop="handleEdit('poker')" :style="{backgroundImage: preConfig.poker}" @mouseenter="changeTable('enter')" @mouseleave="changeTable('leave')"></div>
                    <div id="flop3" class="boardcard" @click.stop="handleEdit('poker')" :style="{backgroundImage: preConfig.poker}" @mouseenter="changeTable('enter')" @mouseleave="changeTable('leave')"></div>
                    <div id="flop4" class="boardcard" @click.stop="handleEdit('poker')" ></div>
                    <div id="flop5" class="boardcard" @click.stop="handleEdit('poker')" ></div>
                  </div>
                </div>
                <div class="seats">
                  <div id="seat0" class="seat same0">
                    <div class="message">
                      <div class="pfp" :style="{backgroundImage: preConfig.avatar}" @click.stop="handleEdit('avatar')" ></div>
                      <div class="name-chips">
                        <img
                        class="shield"
                          :src="preConfig.corner"
                          alt=""
                          @click.stop="handleEdit('corner')"
                        /><span class="letter" @click.stop="handleEdit('corner')"> {{gameConfig.corner==="skin2" ? "" : "$"}}</span>
                        <div class="playName" @click.stop="handleEdit('name')" :style="{backgroundImage:preConfig.nameBg}">Name1</div>
                        <div class="chips" @click.stop="handleEdit('amount')" :style="{backgroundImage:preConfig.amountBg,color:gameConfig.textColor==='skin1' ? '#ffaf10' : 'white'}">$ 352,322</div>
                      </div>
                    </div>
                    <div class="holecards">
                      <div class="holecard1" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                      <div class="holecard2" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                    </div>
                  </div>
                  <div id="seat1" class="seat same0">
                    <div class="message">
                      <div class="pfp" :style="{backgroundImage: preConfig.avatar}" @click.stop="handleEdit('avatar')"></div>
                      <div class="name-chips">
                        <img
                        class="shield"
                          :src="preConfig.corner"
                          alt=""
                         @click.stop="handleEdit('corner')"
                        /><span class="letter" @click.stop="handleEdit('corner')">{{gameConfig.corner==="skin2" ? "" : "$"}}</span>
                        <div class="playName" @click.stop="handleEdit('name')" :style="{backgroundImage:preConfig.nameBg}">Name1</div>
                        <div class="chips" @click.stop="handleEdit('amount')" :style="{backgroundImage:preConfig.amountBg,color:gameConfig.textColor==='skin1' ? '#ffaf10' : 'white'}">$ 352,322</div>
                      </div>
                    </div>
                    <div class="holecards">
                      <div class="holecard1" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                      <div class="holecard2" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                    </div>
                  </div>
                </div>
                <div id="seat2" class="seat same1">
                  <div class="message">
                    <div class="pfp" :style="{backgroundImage: preConfig.avatar}" @click.stop="handleEdit('avatar')"></div>
                    <div class="name-chips">
                      <img
                      class="shield"
                        :src="preConfig.corner"
                        alt=""
                        @click.stop="handleEdit('corner')"
                      /><span class="letter" @click.stop="handleEdit('corner')">{{gameConfig.corner==="skin2" ? "" : "$"}}</span>
                      <div class="playName" @click.stop="handleEdit('name')" :style="{backgroundImage:preConfig.nameBg}">Name1</div>
                      <div class="chips" @click.stop="handleEdit('amount')" :style="{backgroundImage:preConfig.amountBg,color:gameConfig.textColor==='skin1' ? '#ffaf10' : 'white'}">$ 352,322</div>
                    </div>
                  </div>
                  <div class="holecards">
                    <div class="holecard1" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                      <div class="holecard2" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                  </div>
                </div>
                <div id="seat3" class="seat same1">
                <div class="message">
                  <div class="pfp" :style="{backgroundImage: preConfig.avatar}" @click.stop="handleEdit('avatar')"></div>
                  <div class="name-chips">
                    <img
                    class="shield"
                      :src="preConfig.corner"
                      alt=""
                      @click.stop="handleEdit('corner')"
                    /><span class="letter" @click.stop="handleEdit('corner')">{{gameConfig.corner==="skin2" ? "" : "$"}}</span>
                    <div class="playName" @click.stop="handleEdit('name')" :style="{backgroundImage:preConfig.nameBg}">Name1</div>
                    <div class="chips" @click.stop="handleEdit('amount')" :style="{backgroundImage:preConfig.amountBg,color:gameConfig.textColor==='skin1' ? '#ffaf10' : 'white'}">$ 352,322</div>
                  </div>
                </div>
                <div class="holecards">
                 <div class="holecard1" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                      <div class="holecard2" @click.stop="handleEdit('pokerVerso')" :style="{backgroundImage:preConfig.pokerVerso}"></div>
                </div>
              </div>
              </div>

              <!-- <div class="sScore">
                <div class="ss">
                  10k
                  <img
                    src="../../assets/pokerImages/skin1/chip.png"
                    alt=""
                  />
                </div>
              </div> -->
              <div class="dealer">
              <div class="dealer-pfp" @click.stop="handleEdit('woman')" :style="{backgroundImage:preConfig.woman}" ></div>
              <div class="del">TIP DEALER</div>
              <div class="score">
                $ 800,000,000<img
                  src="../../assets/pokerImages/skin1/chip-big.png"
                  alt=""
                />
              </div>
            </div>
              <div class="burns">
              <div class="burn1"  @click.stop="handleEdit('poker')" :style="{backgroundImage: preConfig.poker}"></div>
              <div class="burn2" @click.stop="handleEdit('poker')" :style="{backgroundImage: preConfig.poker}"></div>
              <div class="burn2" @click.stop="handleEdit('poker')" :style="{backgroundImage: preConfig.poker}"></div>
            </div>
              
            </div>

            
            
          </div>
          
          
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",

  data() {
    return {

      gameConfig: {
        woman: "skin1",
        avatar: "skin1",
        bg: "skin1",
        poker:"skin1",
        amount:"skin1",
        name:"skin1",
        foldButton:"skin1",
        table:"skin1",
        textColor:"skin1",
        corner:"skin1"
      },
      selected: "bg",
      dict: {
        // pacman: "人物",
        woman: "人物",
        avatar: "头像",
        // point: "豆子",
        bg: "背景",
        table:"桌子",
        poker:"扑克牌正面",
        pokerVerso:"扑克牌反面",
        amount:"金额背景",
        name:"名称背景",
        foldButton:"按钮背景",
        corner:"角标",
        bottomButton:"角标",

      },
    preConfig:{
        bgImage:"radial-gradient(rgb(83, 104, 71) 20%, rgb(0, 0, 0))",
        avatar:`url(${require("../../assets/pokerImages/skin1/avatar.png")})`,
        table:`url(${require("../../assets/pokerImages/skin1/table.png")})`,
        woman:`url(${require("../../assets/pokerImages/skin1/woman.png")})`,
        nameBg:`url(${require("../../assets/pokerImages/skin1/name.png")})`,
        amountBg:`url(${require("../../assets/pokerImages/skin1/amount.png")})`,
        foldButtonBg:`url(${require("../../assets/pokerImages/skin1/foldButton.png")})`,
        // foldButtonBg:`linear-gradient(to top,rgb(208,161,77),rgb(218,147,33),rgb(236,235,176));`,

        pokerVerso:`url(${require("../../assets/pokerImages/skin1/pokerVerso.png")})`,
        poker:`url(${require("../../assets/pokerImages/skin1/poker.png")})`,
        corner:require("../../assets/pokerImages/skin1/corner.png"),
        bottomButton:`url(${require("../../assets/pokerImages/skin1/foldButton.png")})`,

    },
    
      pacmanItem: {
        bg: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/background.png"),
            title: "Pattern1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/background.jpg"),

            title: "Pattern2",
          },
          {
            value: "skin3",
            img: require("../../assets/pokerImages/skin3/background.jpg"),
            title: "Pattern3",
          },
        ],
        avatar: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/avatar.png"),
            title: "Avatar1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/avatar.png"),
            title: "Avatar2",
          },

        ],
        woman: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/woman.png"),
            title: "Avatar1",
          },
        {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/woman.png"),
            title: "Avatar2",
          },
        ],
        table: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/table.png"),
            title: "Table1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/table.png"),
            title: "Table2",
          },

        ],
        poker: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/poker.png"),
            title: "Front1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/poker.png"),
            title: "Front1",
          },

        ],
        pokerVerso: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/pokerVerso.png"),
            title: "Back1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/pokerVerso.png"),
            title: "Back2",
          },
        ],
        amount: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/amount.png"),
            title: "Background1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/amount.png"),
            title: "Background2",
          },
        ],
        name: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/name.png"),
            title: "Pattern1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/name.png"),
            title: "Pattern2",
          },
        ],
        foldButton: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/foldButton.png"),
            title: "Background1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/foldButton.png"),
            title: "Background2",
          },
        ],
        corner:[
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/corner.png"),
            title: "Background1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/corner.png"),
            title: "Background2",
          },
        ],
bottomButton: [
          {
            value: "skin1",
            img: require("../../assets/pokerImages/skin1/foldButton.png"),
            title: "Background1",
          },
          {
            value: "skin2",
            img: require("../../assets/pokerImages/skin2/foldButton.png"),
            title: "Background2",
          },
        ],
      },
    };
  },
  methods: {
    showText(val) {
      console.log(val);
      switch (val) {
        case "woman":
          return "Choose your dealer";
        case "name":
          return "Choose your name background";
        case "poker":
          return "Choose your cards";
          case "pokerVerso":
          return "Choose your pokerVerso";
          case "table":
          return "Choose your table";
           case "bg":
          return "Choose your background";
          case "foldButton":
          return "Choose your button background";
          case "amount":
          return "Choose the background of your total amount";
          case "avatar":
          return "Choose the player avatar";
      }
    },
    goHomePage() {
      this.$router.push({ path: "/" });
    },
    changeTable(val){
      // console.log(this.$refs.table)
      if(val==="enter" || val==="tableLeave"){
      this.$refs.table.style.transform="scale(1)"

      }else if(val==="leave" || val==="tableEnter"){
      this.$refs.table.style.transform="scale(1.1)"

      }
    },
    handleEdit(val) {
      if(val==="bg"){
        if(this.gameConfig.bg==="skin1"){
            this.preConfig.bgImage="radial-gradient(rgb(83, 104, 71) 20%, rgb(0, 0, 0))"
        }else if(this.gameConfig.bg==="skin2"){
            const img=require("../../assets/pokerImages/skin2/background.jpg")
            this.preConfig.bgImage=`url(${img})`


        }else{
            const img=require("../../assets/pokerImages/skin3/background.jpg")
           this.preConfig.bgImage=`url(${img})`
        }
      }
      if(val==="avatar"){
        if(this.gameConfig.avatar==="skin1"){
            const img=require("../../assets/pokerImages/skin1/avatar.png")
            this.preConfig.avatar=`url(${img})`
        }else if(this.gameConfig.avatar==="skin2"){
            const img=require("../../assets/pokerImages/skin2/avatar.png")
            this.preConfig.avatar=`url(${img})`

        }
      }
      if(val==="table"){
        if(this.gameConfig.table==="skin1"){
            const img=require("../../assets/pokerImages/skin1/table.png")
            this.preConfig.table=`url(${img})`
        }else if(this.gameConfig.table==="skin2"){
            const img=require("../../assets/pokerImages/skin2/table.png")
            this.preConfig.table=`url(${img})`

        }
      }
      if(val==="woman"){
        if(this.gameConfig.woman==="skin1"){
            const img=require("../../assets/pokerImages/skin1/woman.png")
            this.preConfig.woman=`url(${img})`
        }else if(this.gameConfig.woman==="skin2"){
            const img=require("../../assets/pokerImages/skin2/woman.png")
            this.preConfig.woman=`url(${img})`

        }
      }
      if(val==="name"){
        if(this.gameConfig.name==="skin1"){
            const img=require("../../assets/pokerImages/skin1/name.png")
            // console.log(img)
            this.preConfig.nameBg=`url(${img})`

        }else if(this.gameConfig.name==="skin2"){
            const img=require("../../assets/pokerImages/skin2/name.png")
            this.preConfig.nameBg=`url(${img})`

        }
      }
      if(val==="amount"){

        if(this.gameConfig.amount==="skin1"){
            const img=require("../../assets/pokerImages/skin1/amount.png")
            this.preConfig.amountBg=`url(${img})`
        }else if(this.gameConfig.amount==="skin2"){
            const img=require("../../assets/pokerImages/skin2/amount.png")
            this.preConfig.amountBg=`url(${img})`

        }
      }
      if(val==="foldButton"){

        if(this.gameConfig.foldButton==="skin1"){
            const img=require("../../assets/pokerImages/skin1/foldButton.png")
            this.preConfig.foldButtonBg=`url(${img})`
        }else if(this.gameConfig.foldButton==="skin2"){
            const img=require("../../assets/pokerImages/skin2/foldButton.png")

            this.preConfig.foldButtonBg=`url(${img})`
        }
      }
      if(val==="pokerVerso"){

        if(this.gameConfig.pokerVerso==="skin1"){
            const img=require("../../assets/pokerImages/skin1/pokerVerso.png")
            this.preConfig.pokerVerso=`url(${img})`
        }else if(this.gameConfig.pokerVerso==="skin2"){
            const img=require("../../assets/pokerImages/skin2/pokerVerso.png")

            this.preConfig.pokerVerso=`url(${img})`
        }
      }
      if(val==="poker"){

        if(this.gameConfig.poker==="skin1"){
            const img=require("../../assets/pokerImages/skin1/poker.png")
            this.preConfig.poker=`url(${img})`
        }else if(this.gameConfig.poker==="skin2"){
            const img=require("../../assets/pokerImages/skin2/poker.png")

            this.preConfig.poker=`url(${img})`
        }
      }
      if(val==="corner"){

        if(this.gameConfig.corner==="skin1"){
            const img=require("../../assets/pokerImages/skin1/corner.png")

            this.preConfig.corner=img
        }else if(this.gameConfig.corner==="skin2"){
            const img=require("../../assets/pokerImages/skin2/corner.png")

            this.preConfig.corner=img
        }
      }
      if(val==="bottomButton"){

        if(this.gameConfig.bottomButton==="skin1"){
            const img=require("../../assets/pokerImages/skin1/foldButton.png")

            this.preConfig.bottomButton=`url(${img})`
        }else if(this.gameConfig.bottomButton==="skin2"){
            const img=require("../../assets/pokerImages/skin2/foldButton.png")

            this.preConfig.bottomButton=`url(${img})`
        }
      }
      this.selected = val;
    },
    changePreConfig(selected,item){
        this.gameConfig[selected] = item.value
        // console.log(selected,item.value);
        this.handleEdit(selected)
    },
    goGame() {
      let option = JSON.stringify(this.gameConfig);
      option = this.encrypt(option);
        // console.log(option)
        let url = window.location.origin;
      window.open(url+`/poker?gameConfig=${option}`);
    },
    changeTextColor(){
      if(this.gameConfig.textColor==="skin1"){
        this.gameConfig.textColor="skin2"
      }else{
        this.gameConfig.textColor="skin1"
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.icon{
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(../../assets/img/icon.png);
  margin-left: 5px;
}
.game {
  width: 100%;
  height: 100vh;
  background: #000;
  overflow: hidden;

  .game-title {
    background: #393939;
    color: #9a9aa2;
    font-size: 14px;
    height: 35px;
  }

  .game-top {
    background: #1e1e1e;
    height: 50px;
    color: #9a9aa2;

    button {
      background: #f96d20;
      border: none;
      color: white;
      height: 30px;
      width: 100px;
      border-radius: 5px;
    }

    input {
      background: #111111;
      border: 0px;
      height: 32px;
      padding: 0 10px;
      width: 240px;

      &:focus {
        border: 0px;
      }
    }

    .game-top-line {
      width: calc((100% - 200px) / 2);
      height: 2px;
      background: #2c2c2c;
    }
  }

  .game-body {
    height: calc(100vh - 85px);
    color: #9a9aa2;
    position: relative;

    .game-item {
      width: calc((100% / 5) - 20px);
    }

    .game-edit {
      width: 300px;
      background: #202020;
      height: calc(100% - 2px);
      margin-top: 2px;

      .game-edit-title {
        color: #f96d20;
      }

      .game-edit-list {
        height: calc(100% - 70px);
        margin-top: 20px;
        overflow: auto;
      }
    }

    .game-content {
      width: calc(100% - 300px);
      background: rgb(0, 0, 0);
      height: 100%;
      min-width: 1060px;
      position: relative;
    }
  }

  .game-edit-item {
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #f96d20;
      box-shadow: 0px 0px 10px 5px rgba(249, 109, 32, 0.44);
      transition: box-shadow 0.3s;
      transition: border-color 0.3s;
    }
  }

  .game-edit-item-active {
    border: 2px solid #f96d20;
  }

  * {
    color: white;
    text-align: center;
  }
  ///////////
  .poker-table {
    width: 1060px;
    height: 551px;
    /* background:url(../img/大背景.png) no-repeat; */
    
    /* background-size:100% 100%; */
    background-position: center;
    /* background-attachment:fixed; */
    position: absolute;
    /* margin:auto; */
  }
//   .myPhoto {
//     width: 90px;
//     height: 90px;
//     background: url(../../assets/pokerImages/skin1/我的头像.png);
//     background-size: 100% 100%;
//     position: absolute;
//     top: 10px;
//     left: 10px;
//   }
  /* 桌面中心开始*/
  .board {
    width: 820px;
    height: 360px;
    
    background-size: 100% 100%;
    position: absolute;
    top: 90px;
    left: 110px;

  }
  .board_core {
    width: 600px;
    height: 80px;
    position: absolute;
    top: 136px;
    left: 105px;
    border: solid 1px rgba(255, 255, 255, 0.6);
    border-radius: 40px;
  }
  .boardcard_core {
    width: 270px;
    margin: 4px auto;
  }
  .boardcard {
    width: 50px;
    height: 72px;
    background: rgba(0, 0, 0, 0.23);
    display: inline-block;
  }
  #flop1 {
    background: url(../../assets/pokerImages/skin1/poker.png);
    background-size: 100% 100%;
    // position: absolute;
  }
  #flop2 {
    background: url(../../assets/pokerImages/skin1/poker.png);
    background-size: 100% 100%;
  }
  #flop3 {
    background: url(../../assets/pokerImages/skin1/poker.png);
    background-size: 100% 100%;
  }
  /* 座位设置开始 */
  .seat {
    width: 180px;
    position: absolute;
  }
  #seat0 {
    width: 180px;
    height: 160px;
    position: absolute;
  }
  .message {
    width: 150px;
    height: 120px;
    position: absolute;
  }
  .pfp {
    width: 70px;
    height: 70px;
    
    background-size: 100% 100%;
    margin: auto;
  }
  .name-chips {
    width: 140px;
    position: absolute;
    left: 2px;
    z-index: 999;
  }
  .name-chips > img {
    width: 29px;
    height: 33px;
    position: absolute;
    top: 8px;
    left: 5px;
  }
  .letter {
    cursor: pointer;
    position: absolute;
    top: 13px;
    left: 13px;
    color: black;
    margin-left: 2px;
  }
  .name-chips > div {
    height: 25px;
    text-align: center;
  }
  .playName {
    background: rgba(0, 1, 2, 0.2);
    background-size: contain;
  }
  .chips {
   background-size: contain;
    color: #ffaf10;
  }

  .holecards {
    width: 56px;
    height: 40px;
    position: absolute;
    top: 31px;
    right: 0;
  }
  .holecards > div {
    width: 25px;
    height: 36px;
    // background: url(../../assets/pokerImages/skin1/扑克反面.png);
    background-size: 100% 100%;
    display: inline-block;
  }
  #seat1 {
    position: absolute;
    top: 197px;
    left: -66px;
  }
  #seat2 {
    position: absolute;
    top: 0px;
    right: 2px;
  }
  .same1 > .message {
    right: -30px;
  }
  .same1 > .holecards {
    left: 0;
  }
  #seat3 {
    position: absolute;
    top: 213px;
    right: 11px;
  }
  .ss {
    width: 40px;
    height: 16px;
    background: rgba(0, 1, 2, 0.5);
    position: absolute;
    top: 210px;
    right: 133px;
    font-size: 10px;
  }
  .ss > img {
    width: 15px;
    height: 15px;
    position: absolute;
    left: -8px;
  }
  /* 座位设置结束 */
  /* 荷官设置开始 */
  .dealer {
    width: 220px;
    height: 200px;
    position: absolute;
    top: 28px;
    left: 50%;
    margin-left: -110px;
  }
  .dealer-pfp {
    width: 112px;
    height: 140px;
    background: url(../../assets/pokerImages/skin1/woman.png);
    background-size: 100% 100%;
    margin: auto;
  }
  .del {
    width: 128px;
    margin: auto;
    background: rgba(0, 0, 0, 0.23);
    border-radius: 10.4px;
  }
  .score {
    width: 140px;
    background: black;
    margin: 8px auto 0;
    position: absolute;
    left: 42px;
  }
  .score > img {
    width: 30px;
    height: 30px;
    position: absolute;
    left: -16px;
    top: -5px;
  }
  /* 荷官设置结束 */
  .burns {
    width: 160px;
    height: 100px;
    position: absolute;
    top: 345px;
    left: 438px;
  }
  .burns > div {
    display: inline-block;
  }
  .burn1 {
    width: 50px;
    height: 71px;
    background: url(../../assets/pokerImages/skin1/我的扑克正面-中.png);
    background-size: 100% 100%;
  }
  .burn2 {
    width: 35px;
    height: 50px;
    background: url(../../assets/pokerImages/skin1/我的扑克正面-中.png);
    background-size: 100% 100%;
  }
  /* 桌面结束 */
  .select {
    width: 110px;
    height: 260px;
    position: absolute;
    left: 950px;
    top: 13px;
    color: rgba(255, 255, 255, 0.6);
    display: inline-block;
  }
  .date {
    width: 110px;
    height: 44px;

    line-height: 44px;
  }
  .selector {
    height: 32px;
    line-height: 32px;
    margin: 5px 0;
    text-align: left;
    text-indent: 12px;
    background: rgba(0, 0, 12, 0.8);
    cursor: pointer;
  }
  .selector:hover {
    box-shadow: 3px 3px 4px #000000;
  }
  select {
    background-color: rgba(0, 0, 12, 0.8);
    border: none;
  }
  /* 桌面底部开始 */
  .table-foot {
    width: 420px;
    height: 60px;
    margin: auto;
    position: absolute;
    left: 50%;
    margin-left: -210px;
    top: 476px;
    color: rgba(0, 2, 4, 0.6);
  }
  .button {
    width: 76px;
    height: 30px;
    // background: #ffa000;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 30px;
    color: #000;
    cursor: pointer;
  }
  .raise {
    width: 220px;
    background: rgba(0, 0, 0, 0.5);
    border: solid 1px white;
    display: inline-block;
    border-radius: 23.4px;
    position: relative;
    top: 10px;
  }
  .raise > img {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 25px;
    top: 2px;
  }
  /* 桌面底部结束 */
  /* 底部 开始*/
  .foot {
    width: 1060px;
    height: 40px;
    margin: auto;
    background: #000;
    position: absolute;
    top: 551px;
    left: 0;
  }
  .foot > ul {
    padding-top: 5px;
    padding-left: 200px;
    position: absolute;
    top: -16px;
  }
  .foot > ul > li {
    list-style: none;
    width: 72px;
    height: 30px;
    float: left;
    margin-right: 10px;
    line-height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    color: black;
  }

  #poker_table {
    top: 50%;
    margin-top: -275px;
    left: 50%;
    margin-left: -530px;
  }
  /* 底部结束 */
}
.holecard1,.holecard2,.board,.boardcard,.flop1,.pfp,.button,.dealer-pfp,.shield,.playName,.chips,#poker_table,.game-content{
  transition: all 0.2s;
  cursor: pointer;
}
.holecard1:hover{
  transform: scale(1.5);
}
.holecard2:hover{
  transform: scale(1.5);
}
.board:hover{
  transform: scale(1.1);

}
#flop1:hover{
  transform: scale(1.5);

}
#flop2:hover{
  transform: scale(1.5);

}
#flop3:hover{
  transform: scale(1.5);

}
.pfp:hover{
  transform: scale(1.5);

}
.button:hover{
  transform: scale(1.5);

}
.dealer-pfp:hover{
  transform: scale(1.3);

}
.shield:hover{
  transform: scale(1.5);

}
.playName:hover{
  transform: scale(1.1);

}
.chips:hover{
  transform: scale(1.1);

}
#poker_table:hover{
  transform: scale(1.02);

}
</style>
