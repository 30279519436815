<template>
  <div class="welcome">
    <div class="head">

      <img class="welcomeLogo" src="../../assets/homeImage/welcomeLogo.png" alt="">
      <div class="systemInfo">
        <div class="mail">
          <img src="../../assets/homeImage/email.png" alt="" />
          <span>3</span>
        </div>
        <div class="letter">
          <img src="../../assets/homeImage/letter.png" alt="" />
          <span>3</span>
        </div>
        <div class="userName">
          <img src="../../assets/homeImage/avatar.png" alt="" />
          <p>Gamecraft3</p>
          <img
            class="arrowDown"
            src="../../assets/homeImage/arrowDown.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="menuCOntainer" >
        <div class="menuItem" :class="{ menuActive: activePage === 1 }" @click="goPage('store')">
        Store
        <span class="line" v-if="activePage === 1"></span>
      </div>
      <div class="menuItem" :class="{ menuActive: activePage === 2 }" @click="goPage('library')">
        Library
        <span class="line" v-if="activePage === 2"></span>
      </div>
      <div class="menuItem" :class="{ menuActive: activePage === 3 }" @click="goPage('Community')">
        Community
        <span class="line" v-if="activePage === 3"></span>
      </div>
      <div class="menuItem" :class="{ menuActive: activePage === 4 }" @click="goPage('gameCreater')">
        Game Creater
        <span class="line" v-if="activePage === 4"></span>
      </div>
      </div>
      
    </div>
    <div class="body">
      <div class="userInfo">
        <img src="../../assets/homeImage/avatar.png" alt="" class="avatar" />
        <p>Gamecraft3</p>
        <!-- <p>名字 LanZhou</p> -->
        <p>Gamecraft3</p>
      </div>
      <div class="gameInfo">
        <div class="cursorContainer">
          <div class="carouselLeft" @click="changeActiveCarouse('left')"></div>
          <div
            class="carouselRight"
            @click="changeActiveCarouse('right')"
          ></div>

          <el-carousel
            indicator-position="outside"
            class="carousel"
            height="480px"
            ref="carouse"
            @change="changeBanner"
            arrow="never"
          >
            <el-carousel-item v-for="item in carouselList" :key="item">
              <div class="carouselContain">
                <img :src="item" alt="" />
                <div class="bottomTips">PIXELDEFENSE</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="selectGame">
          <p>Recommended Games</p>
          <div class="selectButton" @click="showGameList('left')"></div>
          <div class="selectButton" @click="showGameList('right')"></div>
          <div class="gameList">
            <!-- indicator-position="none" -->
            <el-carousel
              class="gamseSelectCarousel"
              arrow="never"
              :autoplay="false"
              height="232px"
              ref="gameList"
              indicator-position="none"
            >
              <el-carousel-item v-for="item in 2" :key="item">
                <div class="selectGameContainer">
                  <div class="gameItem" style="">
                    <img src="../../assets/homeImage/game.png" alt="" />
                    <p>THE CARD GAME</p>
                  </div>
                  <div class="gameItem" style="">
                    <img src="../../assets/homeImage/pacman.png" alt="" />
                    <p>Pac-Man</p>
                  </div>
                  <div class="gameItem" style="">
                    <img src="../../assets/homeImage/poker.png" alt="" />
                    <p>Poker</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <!-- <div class="gameItem" style="">
              <img src="../../assets/homeImage/game.png" alt="" />
              <p>THE CARD GAME</p>
            </div>
            <div class="gameItem" style="">
              <img src="../../assets/homeImage/pacman.png" alt="" />
              <p>纸牌</p>
            </div>
            <div class="gameItem" style="">
              <img src="../../assets/homeImage/poker.png" alt="" />
              <p>吃豆人</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import img1 from "../../assets/homeImage/banner1.jpg";
import img2 from "../../assets/homeImage/banner2.jpg";
import img3 from "../../assets/homeImage/banner3.jpg";

export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      carouselList: [img1, img2, img3],
      activePage: 1,
      activeCarouse: 0,
      activeGameList: 0,
    };
  },
  methods: {
    changeBanner(currentIndex){
      // console.log(currentIndex)
      this.activeCarouse=currentIndex;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    changeActiveCarouse(value) {
      if (value === "left") {
        if (this.activeCarouse === 0) {
          return;
        }
        this.$refs.carouse.setActiveItem(this.activeCarouse - 1);
        this.activeCarouse -= 1;
      } else {
        if (this.activeCarouse === 2) {
          return;
        }
        this.$refs.carouse.setActiveItem(this.activeCarouse + 1);
        this.activeCarouse += 1;
      }
    },
    showGameList(value) {
      if (value === "left") {
        if (this.activeGameList === 0) {  
          return;
        }

        this.$refs.gameList.setActiveItem(this.activeGameList - 1);
        this.activeGameList -= 1;
      } else {
        if (this.activeGameList === 1) {
          return;
        }
        this.$refs.gameList.setActiveItem(this.activeGameList + 1);
        this.activeGameList += 1;
      }
    },
    goPage(value){
      switch(value){
        case "store" :
          this.activePage=1;
          break;
          case "library" :
          this.activePage=2;
          break;
          case "Community" :
          this.activePage=3;
          break;
          case "gameCreater" :
          this.activePage=4;
          this.$router.push("/selectGame")
          break;
      }
    }
  },
};
</script>


<style lang="scss" scoped>
* {
  margin: 0;
}
.head {
  width: 100%;
  height: 100px;
  color: rgb(155, 160, 164);
  background-color: rgb(3, 21, 23);
  font-size: 25px;
  line-height: 100px;
  padding-left: 44px;
  padding-right: 44px;
  min-width: 1450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.el-menu {
  display: flex;
  justify-content: center;
}
.menu {
  height: 100px;
  width: 100%;
  background-color: rgb(10, 34, 38);
  color: rgb(109, 123, 136);
  display: flex;
  justify-content: space-around;
  font-size: 27px;
  // width: 1200px;
  min-width: 1450px;

  .menuItem {
    padding-left: 89px;
    padding-right: 89px;
    line-height: 100px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .menuItem:nth-of-type(1){
    padding-left: 0;
    text-align: center;
    width: 217px;
    padding-right: 0;
    transform: translateX(-57px);
  }
  .line {
    background-color: rgb(2, 128, 178);
    width: 80px;
    height: 8px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -40px;
    display: block;
    box-shadow: 0px 23px 62px 32px rgb(2 128 178);
  }
}
.body {
  width: 100%;
  padding-top: 20px;
  background-color: rgb(1, 31, 55);
  display: flex;
  justify-content: center;
  min-width: 1450px;
}
.userInfo {
  flex-shrink: 0;
  width: 322px;
  height: 800px;
  background-color: rgb(0, 18, 30);
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding-top: 36px;
  margin-left: 20px;
  margin-bottom: 30px;

  p:nth-of-type(1) {
    color: white;
    margin: 0;
    font-size: 27px;
    margin-top: 23px;
  }
  p:nth-of-type(2) {
    color: rgb(99, 112, 121);
    margin: 0;
    margin-top: 30px;
    font-size: 25px;
  }
  p:nth-of-type(3) {
    color: rgb(99, 112, 121);
    margin: 0;
    font-size: 25px;
    margin-top: 10px;
  }
  .avatar {
    width: 240px;
    height: 240px;
    border: 5px solid rgb(2, 128, 178);
  }
}
.gameInfo {
  //   width: calc(100vw - 368px);
  min-width: 1000px;
  width: 1560px;
}
.carousel {
  margin-left: 100px;
  margin-right: 100px;
  min-width: 910px;
  // width: 1380px;
}
.selectGame {
  font-size: 26px;
  color: white;
  margin-left: 100px;
  margin-right: 100px;
  min-width: 920px;
  position: relative;
  .selectButton {
    width: 60px;
    height: 120px;
    position: absolute;
  }
  .selectButton:nth-of-type(1) {
    top: 111px;
    left: -83px;
    background-image: url(../../assets/homeImage/carouselLeft.png);
  }
  .selectButton:nth-of-type(2) {
    top: 111px;
    right: -70px;

    background-image: url(../../assets/homeImage/carouselRight.png);
  }
}
.gameList {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  height: 232px;
  align-items: center;
}
.gameItem {
  width: 32.4%;
  height: max-content;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
  p {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    padding-left: 20px;
  }
  img {
    width: 100%;
  }
}
//   .gameItem:nth-of-type(1){
//     background-image: url(../../assets/homeImage/game.png);
//   }
//   .gameItem:nth-of-type(2){
//     background-image: url(../../assets/homeImage/pacman.png);
//   }
//   .gameItem:nth-of-type(3){
//     background-image: url(../../assets/homeImage/poker.png);
//   }
.mail,
.letter {
  width: 110px;
  height: 60px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.mail {
  background-color: rgb(82, 126, 1);
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}
.letter {
  background-color: rgb(0, 129, 176);
  img {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }
}
.systemInfo {
  display: flex;
  align-items: center;
}
.userName {
  width: 348px;
  height: 60px;
  background-color: rgb(6, 35, 51);
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }
  .arrowDown {
    background-size: contain;
    width: 17px;
    height: 8px;
  }
}
.carouselContain {
  position: relative;
  height: 480px;
}
.bottomTips {
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
  // top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  color: white;
  line-height: 60px;
  box-sizing: border-box;
  padding-left: 20px;
  font-size: 21px;
}
.menuActive {
  color: white;
}
.cursorContainer {
  position: relative;
  min-width: 1100px;
  width: 100%;
  .carouselLeft,
  .carouselRight {
    width: 60px;
    height: 120px;
    position: absolute;
  }
  .carouselLeft {
    top: 164px;
    left: 20px;
    background-image: url(../../assets/homeImage/carouselLeft.png);
  }
  .carouselRight {
    top: 162px;
    right: 23px;
    background-image: url(../../assets/homeImage/carouselRight.png);
  }
}
.selectGameContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.gamseSelectCarousel {
  width: 100%;
}
.menuCOntainer{
  width: 1869px;
  display: flex;
  justify-content: space-between;
}
.welcomeLogo{
  height: 50px;
}
// .carouserlContainer{
//   display: flex;
// }
</style>