<template>
  <div class="game">
    <div class="game-title flex align-items-center justify-content-between">
      <div
        class="flex align-items-center pl10"
        style="width: calc((100% - 300px) / 2)"
      >
        <img src="../../assets/img/Icon-32x32.png" height="24" width="24" />
        <span class="pl20">File</span>
        <span class="pl20">Edit</span>
        <span class="pl20">Add</span>
        <span class="pl20">View</span>
        <span class="pl20">Tools</span>
        <span class="pl20">Help</span>
      </div>
      <div>Creator</div>
      <div
        class="pr10 flex align-items-center justify-content-end pointer"
        style="width: calc((100% - 300px) / 2)"
        @click="goHomePage"
      >
        x
      </div>
    </div>
    <div class="game-top flex align-items-center justify-content-end pr20">
      <button @click="goGame()">Start Game</button>
    </div>
    <div class="game-body flex align-items-center">
      <div class="game-edit pl10 pr10 pt20">
        <div class="game-edit-title font-bold font-size-xl">
          <!-- 请选择"{{ dict[selected] }}"的样式: -->
          {{ showText(selected) }}
        </div>
        <div
          class="game-edit-list flex flex-column align-items-center"
          v-if="pacmanItem[selected]"
        >
          <div
            class="game-edit-item mb20"
            :class="{
              'game-edit-item-active': item.value == gameConfig[selected],
            }"
            v-for="item in pacmanItem[selected]"
            :key="item.value"
            @click="changeGameConfig(selected, item)"
          >
            <img :src="item.img" width="220px" />
            <div class="flex align-items-center justify-content-center">
              {{ item.title }}<span class="icon"></span>125
            </div>
          </div>
        </div>
      </div>
      <div class="game-content flex align-items-center justify-content-center">
        <div
          class="bgContainer flex align-items-center justify-content-center"
          style="color: white"
          :style="{ backgroundImage: preConfig.bg }"
          @click.stop="handleEdit('bg')"
        >
          <!-- <img class="bgimage" src="../../assets/pacman/pacmanBackground/background.png" alt=""> -->
          <!-- <button @click="handleEdit('bg')">背景</button>
          <button @click="handleEdit('pacman')">人物</button>
          <button @click="handleEdit('ghosts')">怪兽</button>
          <button @click="handleEdit('wall')">墙体</button>
          <button @click="handleEdit('point')">豆子</button> -->
        </div>
        <div
          class="wallContainer"
          :style="{ backgroundImage: preConfig.wallBg }"
          @click.stop="changeWallBg()"
        ></div>
        <div
          class="pointContainer"
          @click.stop="changePointBg()"
          :style="{ backgroundImage: preConfig.pointBg }"
        ></div>
        <div
          class="pacman"
          :style="{ backgroundImage: preConfig.pacman }"
          @click.stop="handleEdit('pacman')"
        ></div>
        <div
          class="ghosts"
          :style="{ backgroundImage: preConfig.ghosts }"
          @click.stop="handleEdit('ghosts')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { encrypt, decrypt } from "@/js/encrypt";

export default {
  name: "index",
  data() {
    return {
      gameConfig: {
        pacman: 1,
        ghosts: 1,
        wall: 1,
        point: 1,
        bg: 1,
      },
      selected: "bg",
      dict: {
        pacman: "人物",
        ghosts: "怪兽",
        wall: "墙体",
        point: "豆子",
        bg: "背景",
      },
      preConfig: {
        wallBg: `url(${require("../../assets/pacman/pacmanWallBg/pacmanWallBg1.png")})`,
        pointBg: `url(${require("../../assets/pacman/pacmanPointBg/pacmanPointBg1.png")})`,
        pacman: `url(${require("../../assets/pacman/pacman/pacman1.png")})`,
        ghosts: `url(${require("../../assets/pacman/ghosts/ghosts1.png")})`,
        bg: `url(${require("../../assets/pacman/bg/bg1.png")})`,

        // pacman:`url(${require("../../assets/pacman/pacman/pacman1.png")})`,
        // ghosts:`url(${require("../../assets/pacman/ghosts/ghosts.png")})`,
        // wall:`url(${require("../../assets/pacman/wall/wall1.png")})`,
        // point:`url(${require("../../assets/pacman/point/point1.png")})`,
        // bg:`url(${require("../../assets/pacman/bg/bg1.png")})`,
      },
      double: 0,
      pacmanItem: {
        bg: [
          {
            value: 1,
            img: require("../../assets/pacman/bg/bg1.png"),
            title: "Background image 1",
          },
          {
            value: 2,
            img: require("../../assets/pacman/bg/bg2.png"),
            title: "Background image 1",
          },
          {
            value: 3,
            img: require("../../assets/pacman/bg/bg3.png"),
            title: "Background image 1",
          },
        ],
        ghosts: [
          {
            value: 1,
            img: require("../../assets/pacman/ghosts/ghosts1.png"),
            title: "Enemy 1",
          },
          {
            value: 2,
            img: require("../../assets/pacman/ghosts/ghosts2.png"),
            title: "Enemy 2",
          },
          {
            value: 3,
            img: require("../../assets/pacman/ghosts/ghosts3.png"),
            title: "Enemy 3",
          },
        ],
        pacman: [
          {
            value: 1,
            img: require("../../assets/pacman/pacman/pacman1.png"),
            title: "Oneself color 1",
          },
          {
            value: 2,
            img: require("../../assets/pacman/pacman/pacman2.png"),
            title: "Oneself color 2",
          },
        ],
        wall: [
          {
            value: 1,
            img: require("../../assets/pacman/wall/wall1.png"),
            title: "Wall 1",
          },
          {
            value: 2,
            img: require("../../assets/pacman/wall/wall2.png"),
            title: "Wall 2",
          },
          {
            value: 3,
            img: require("../../assets/pacman/wall/wall3.png"),
            title: "Wall 3",
          },
        ],
        point: [
          {
            value: 1,
            img: require("../../assets/pacman/point/point1.png"),
            title: "Color of beans 1",
          },
          {
            value: 2,
            img: require("../../assets/pacman/point/point2.png"),
            title: "Color of beans 1",
          },
          {
            value: 3,
            img: require("../../assets/pacman/point/point3.png"),
            title: "Color of beans 1",
          },
        ],
      },
    };
  },
  methods: {
    showText(val) {
      console.log(val);
      switch (val) {
        case "bg":
          return "Choose your game background";
        case "pacman":
          return "Choose your pac-man color";
        case "point":
          return "Choose your dots";
          case "ghosts":
          return "Choose your ghosts";
          case "wall":
          return "Choose your wall";
      }
    },
    goHomePage() {
      this.$router.push({ path: "/" });
    },
    changePreView(mode, value) {
      console.log(value, mode);
      switch (value) {
        case 1:
          if (mode === "point") {
            const url1 = require(`../../assets/pacman/pacmanPointBg/pacmanPointBg1.png`);
            this.preConfig.pointBg = `url(${url1})`;
          } else if (mode === "wall") {
            const url1 = require(`../../assets/pacman/pacmanWallBg/pacmanWallBg1.png`);
            this.preConfig.wallBg = `url(${url1})`;
          } else {
            const url1 = require(`../../assets/pacman/${mode}/${mode}1.png`);
            this.preConfig[mode] = `url(${url1})`;
          }

          break;
        case 2:
          if (mode === "point") {
            const url2 = require(`../../assets/pacman/pacmanPointBg/pacmanPointBg2.png`);

            this.preConfig.pointBg = `url(${url2})`;
          } else if (mode === "wall") {
            const url2 = require(`../../assets/pacman/pacmanWallBg/pacmanWallBg2.png`);
            this.preConfig.wallBg = `url(${url2})`;
          } else {
            const url2 = require(`../../assets/pacman/${mode}/${mode}2.png`);

            this.preConfig[mode] = `url(${url2})`;
          }
          break;
        case 3:
          if (mode === "point") {
            const url3 = require(`../../assets/pacman/pacmanPointBg/pacmanPointBg3.png`);
            this.preConfig.pointBg = `url(${url3})`;
          } else if (mode === "wall") {
            const url3 = require(`../../assets/pacman/pacmanWallBg/pacmanWallBg3.png`);
            this.preConfig.wallBg = `url(${url3})`;
          } else {
            const url3 = require(`../../assets/pacman/${mode}/${mode}3.png`);
            this.preConfig[mode] = `url(${url3})`;
          }
          break;
      }
      // console.log(this.preConfig)
    },
    handleEdit(val) {
      this.selected = val;
    },
    goGame() {
      let option = JSON.stringify(this.gameConfig);
      option = this.encrypt(option);
      let url = window.location.origin;
      window.open(url+"/pacman?config=" + option);
    },
    changeGameConfig(selected, item) {
      this.gameConfig[selected] = item.value;
      // console.log(selected,item)
      this.changePreView(selected, item.value);
    },
    changePointBg() {
      this.handleEdit("point");
      // this.double += 1;
      // setTimeout(() => {
      //   if (this.double === 2) {
      //     this.double = 0;
      //     this.handleEdit("wall");
      //   } else if (this.double === 1) {
      //     this.double = 0;
      //     this.handleEdit("point");
      //   }
      // }, 600);
    },
    changeWallBg() {
      this.handleEdit("wall");
    },
    // changeWallBg(){
    //   console.log(88888)
    // }
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-image: url(../../assets/img/icon.png);
  margin-left: 5px;
}
.game {
  width: 100%;
  height: 100vh;
  background: #000;
  overflow: hidden;

  .game-title {
    background: #393939;
    color: #9a9aa2;
    font-size: 14px;
    height: 35px;
  }

  .game-top {
    background: #1e1e1e;
    height: 50px;
    color: #9a9aa2;

    button {
      background: #f96d20;
      border: none;
      color: white;
      height: 30px;
      width: 100px;
      border-radius: 5px;
    }

    input {
      background: #111111;
      border: 0px;
      height: 32px;
      padding: 0 10px;
      width: 240px;

      &:focus {
        border: 0px;
      }
    }

    .game-top-line {
      width: calc((100% - 200px) / 2);
      height: 2px;
      background: #2c2c2c;
    }
  }

  .game-body {
    height: calc(100vh - 85px);
    color: #9a9aa2;

    .game-item {
      width: calc((100% / 5) - 20px);
    }

    .game-edit {
      width: 300px;
      background: #202020;
      height: calc(100% - 2px);
      margin-top: 2px;

      .game-edit-title {
        color: #f96d20;
        text-align: center;
      }

      .game-edit-list {
        height: calc(100% - 70px);
        margin-top: 20px;
        overflow: auto;
      }
    }

    .game-content {
      width: calc(100% - 300px);
      background: #111111;
      height: 100%;
      position: relative;
      // transition: all 0.2s;
    }
  }

  .game-edit-item {
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #f96d20;
      box-shadow: 0px 0px 10px 5px rgba(249, 109, 32, 0.44);
      transition: box-shadow 0.3s;
      transition: border-color 0.3s;
    }
  }

  .game-edit-item-active {
    border: 2px solid #f96d20;
  }
}
.bgContainer {
  width: 580px;
  height: 430px;
  position: absolute;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
  // background-color: red;
}
.wallContainer {
  width: 534px;
  height: 384px;
  position: absolute;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }

  // background-color: red;
}
.pointContainer {
  width: 474px;
  height: 316px;
  transition: all 0.2s;
  background-repeat: no-repeat;
  background-position: -18px 2px;
  z-index: 99;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}

.pacman {
  width: 40px;
  height: 40px;
  // background-color: #f96d20;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 260px);
  background-size: cover;
  z-index: 9999;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.3);
  }
  &:hover :not(.pacman) {
    transform: scale(1);
  }
}

.ghosts {
  position: absolute;
  cursor: pointer;
  z-index: 9999;
  background-size: cover;
  top: calc(50% - 40px);
  left: calc(50% - 60px);
  width: 121px;
  height: 40px;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.3);
  }
}
</style>
