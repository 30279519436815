import Vue from 'vue'
import VueRouter from 'vue-router'
import game from '../views/game/index'
import home from '../views/home/index'
import welcome from '../views/welcome/index'

import pokerInit from "../views/pokerInit/index"
Vue.use(VueRouter)

const routes = [
  {
    path:"/index.html",
    redirect:"/"
  },
  {
    path: '/selectGame',
    name: 'home',
    component: home,
  },
  {
    path: '/game',
    name: 'game',
    component: game
  },
  {
    path: '/pokerInit',
    name: 'pokerInit',
    component: pokerInit
  },
  {
    path: '/',
    name: 'welcome',
    component: welcome,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
