<template>
  <div class="home">
    <div class="home-title flex align-items-center justify-content-center">
      Creator
    </div>
    <div class="home-top">
      <div class="flex align-items-center justify-content-center pt20">
        <label>Name：</label>
        <input type="text" v-model="name" placeholder="Untitled" @keyup.enter="submit"/>
      </div>
      <div class="flex align-items-center justify-content-between" style="height: 100px">
        <div class="home-top-line"></div>
        <div class="flex align-items-center justify-content-center">
          <img src="../../assets/game.png" height="44" width="52"/>
          <span style="color: white" class="ml10">Gameplay Type</span>
        </div>
        <div class="home-top-line"></div>
      </div>
    </div>
    <div class="home-body flex align-items-center justify-content-center carousel">
      <el-carousel ref="carousel" :interval="400000" type="card" height="60vh" style="width: 80%" indicator-position="none" arrow="never">
        <el-carousel-item v-for="(item,index) in gameList" :key="index" >
          <img :src="item.img" style="width: 100%" @click="goGamePage(index)"/>
          <div class="flex align-items-center justify-content-center font-size-xl font-bold" style="color: #f96d20" @click="goGamePage(index)">{{item.name}}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      name: undefined,
      gameList: [
        {
          name: "pacman",
          img: require("../../assets/homeImage/pacman.png"),
        },
        {
          name: "poker",
          img: require("../../assets/homeImage/poker.png"),
        },

      ],
    }
  },
  methods: {
    submit() {
      if (this.name) {
        let index = this.gameList.findIndex(item => item.name.indexOf(this.name) != -1)
        if (index != -1) {
          this.$refs.carousel.setActiveItem(index)
          this.name = undefined
        }
      }
    },
    goGamePage(index) {
      switch(index){
        case 0 : 
            this.$router.push({path: "/game"}) 
            break;
        case 1 : 
            this.$router.push({path: "/pokerInit"})
            break;
      }
    },
  }
}
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100vh;
  background: #000;

  .home-title {
    background: #393939;
    color: #9a9aa2;
    font-size: 14px;
    height: 35px;
  }

  .home-top {
    background: #1e1e1e;
    height: 150px;
    color: #9a9aa2;

    input {
      background: #111111;
      border: 0px;
      height: 32px;
      padding: 0 10px;
      width: 240px;
      color: #9a9aa2;
      outline: none;

      &:focus {
        outline: none;
        border: 0px !important;
      }
    }

    .home-top-line {
      width: calc((100% - 200px) / 2);
      height: 2px;
      background: #2c2c2c;
    }
  }

  .home-body {
    height: calc(100vh - 185px);
    color: #9a9aa2;
    .game-item {
      width: calc((100% / 5) - 20px);
    }
  }
}
.carousel {
 .el-carousel__mask {
    opacity: 0;
  }
  .el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 0;
  }
}

</style>
